* {
  box-sizing: border-box;
}

:root {
  --main-color: #CEFACD;
}

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: "Patua one" "Oswald" "Rawline";
  background-color: black;
}

.App {
  display: flex;
  flex-direction: column;
  background: url("../../img/bg.png");
  background-size: cover;
}

.App2 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.version {
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 0.8em;
}

.debug {
  position: fixed;
  bottom: 0;
  right: 0;
  font-size: 0.8em;
  color: #FFF;
  background: red;
}

.screen {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.screen .screen-content {
  flex-grow: 1;
}

button.link-btn {
  background: none;
  border: none;
  padding: 0;
  color: darkblue;
  cursor: pointer;
}

button.link-btn:hover {
  text-decoration: underline;
}

.error {
  color: darkred;
}