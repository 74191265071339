.GameButtons {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;

}

.GameButtons .ButtonsContainer
{
  position: absolute;
  display: flex;
  flex-direction: row;

  top: 15px;
  right: 15px;
}

.GameButtons .btn {
  display: flex;
  pointer-events: all;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  background-color: #11273c;
  border-radius: 100px;
  /* padding: 14px; */
  /* margin: 15px; */

  margin-left: 30px;

  cursor: pointer;
}

.GameButtons .btn img {
  height: 35px;
}

.GameButtons .btn:hover {
  height: 90px;
  width: 90px;
  margin-left: 20px;
}

.GameButtons .btn:hover img {
  height: 40px;
}
