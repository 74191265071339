.PopupVideo {
  position: absolute;
  background: #000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.PopupVideo video {
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.PopupVideo .Title {
  position: absolute;
  top: 0;
  left: 0;
  height: 135px;
  width: 1200px;
  background-image: url("../../img/video_title_bg.png");
  background-repeat: no-repeat;
}

.PopupVideo h1 {
  position: absolute;
  color: white;
  top: 5px;
  left: 25px;
  font-size: 48px;
  font-weight: 100;
  letter-spacing: 7px;
  font-family: "Patua one";
  cursor: default;
}

.PopupVideo .Skip {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 180px;
  width: 380px;
  background-image: url("../../img/bg_skip.png");
  background-repeat: no-repeat;
  cursor: pointer;
}

.PopupVideo .Skip p {
  position: absolute;
  color: rgb(25, 236, 173);
  font-family: "Patua one";
  font-weight: 100;
  font-size: 25px;
  letter-spacing: 7px;
  bottom: -5px;
  right: 1px;
  cursor: pointer;
}

.PopupVideo .Skip:hover p {
  text-shadow: 0px 0px 7px rgba(25, 236, 173, 0.7);
}