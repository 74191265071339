.ScreenHome {
  width: 100%;
  height: 100%;
  background-image: url("../../img/bg_filter.png");
  background-size: cover;
}

.ScreenHome .Content {
  display: flex;
  flex-direction: column;
  width: 900px;
  height: 100%;
}

.ScreenHome .Content img {
  margin-top: 12%;
  margin-left: 65px;
  width: 15%;
}

.ScreenHome .Content h1 {
  margin: 0;
  padding: 0;
  margin-top: 9%;
  margin-left: 105px;
  color: white;
  font-size: 85px;
  font-weight: 100;
  font-family: "Oswald";
  letter-spacing: 8px;
}

.ScreenHome .Content h1 b {
  color: #1fe9ad;
  font-size: 140px;
  font-weight: 100;
  /* letter-spacing: -17px; */
}

.ScreenHome .Content h2 {
  margin: 0;
  margin-left: 65px;
  margin-right: 165px;
  color: white;
  background-color: #3a4b5d;
  padding: 30px;
  border-radius: 25px;
  font-size: 25px;
  letter-spacing: 3px;
  font-weight: 800;
  font-family: "Rawline";
}

.ScreenHome .Content .Buttons {
  display: flex;
  margin-top: 65px;
  margin-left: 45px;
}

.ScreenHome .Content .Buttons button {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-items: center;
  align-items: center;
  background-color: white;
  font-size: 25px;
  font-weight: 100;
  letter-spacing: 5px;
  font-family: "Patua one";
  border: solid 5px #1fe9ad;
  border-radius: 50px;
  padding: 2px 30px;
  color: #11273c;
  cursor: pointer;
}

.ScreenHome .Content .Buttons button.linear {
  margin-right: 70px;
}

.ScreenHome .Content .Buttons button img {
  width: 65px;
  margin: 5px;
  padding: 0;
  margin-left: 2px;
  margin-right: 13px;
  /* height: 25px; */
}

.ScreenHome .Content .Buttons button:hover {
  filter: brightness(0.7);
}