.ScreenChat {
  display: flex;
  flex-direction: column;

}

.ScreenChat .chat {
  padding: 20px;
  /* flex-grow: 1; */

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.ScreenChat .dial {
  background: #EEE;
  margin: 10px;
  padding: 10px;
  max-width: 500px;
  border-radius: 10px;

}

.ScreenChat .dial.Player {
  align-self: flex-start;
  background: pink;

}
.ScreenChat .chat-finished {
  font-size: 18pt;
  font-weight: bold;
  text-align: center;
}