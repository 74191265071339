@font-face {
  font-family: "Patua one";
  src: url("PatuaOne-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("Oswald-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Rawline";
  src: url("rawline-100.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}